import { createPinia } from 'pinia';
import useRootStore from '@/store/modules/root';
import useSocketStore from '@sms/common/store/socket';

/* 型別 */
import type { TableInfoListenEvents, TableInfoInvokeEvents } from '@/api/socket';

export const pinia = createPinia();

export default {
  useRootStore,
  useSocketStore: useSocketStore<TableInfoListenEvents, TableInfoInvokeEvents>()
};
